import Highcharts from 'highcharts';
import { Row, Col, Container, Table, Tabs, Tab, Button } from "react-bootstrap";
import { useEffect, useState } from 'react';
import request from './Api';
import { createHashHistory } from 'history';
import { BsFillPersonLinesFill } from "react-icons/bs"
import TeamScore from "./TeamScore"
function GameResult() {
    const [airportStatistic, setAirportStatistic] = useState([]);

    const [selectedAirport, setAirport] = useState(null);
    const [airportShiftStatistic, setAirportShiftStatistic] = useState([]);
    const [combinedShiftStatistic, setCombinedShiftStatistic] = useState([]);
    const history = createHashHistory();
    function renderChart(container, title, categories, series) {
        Highcharts.chart(container, {
            exporting: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            title: {

                text: title
            },
            xAxis: {
                //categories: ['Alpha1', 'Alpha2', 'Alpha3', 'Alpha4', 'Alpha5', 'Alpha6']
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Aircrafts Revenue'
                },
                stackLabels: {  // 堆叠数据标签
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        this.series.name + ': ' + this.y + '<br/>' +
                        'Total: ' + this.point.stackTotal;
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
                        style: {
                            textOutline: '1px 1px black'
                        }
                    }
                },
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            if (container == "combinedContainer") {                                
                                setAirport({ airportName: event.point.category, shiftName: event.point.series.name });
                            }
                        }
                    }
                }
            },

            series: series
        });
    }
    function sum(arary) {
        var reuslt = 0;
        arary.forEach(a => reuslt += a);
        return reuslt;
    }
    function formatStatisticResult(num){
        if(isNaN(num)){
            return 0;
        }
        if(num==Infinity){
            return 100;
        }
        return num;
    }
    function bindAirport(selectedAirport) {

        var airport = airportStatistic.find(a => a.airportName == selectedAirport.airportName);

        var teams = airport.teams;
        var categories = teams.map(a => a.name);
        var series = [];
        airportStatistic[0].shifts.forEach(a => {
            series.push({
                name: "Shift " + a.index,
                data: airport.shifts.find(s => s.index == a.index).teams.map(a => a.revenue)
            })
        })
        var title = selectedAirport.airportName + ": " + formatStatisticResult(airport.revenue / airport.target * 100).toFixed(1) + "% OF TARGET REACHED";
       console.log(title, categories, series);
        renderChart("airportContainer", title, categories, series)
        var statistic = [];
        if (airport.shifts.length > 1) {
            var shift1Revenue=airport.shifts[0].revenue;
            var shift2Revenue=airport.shifts[1].revenue;
            var shift1LandingSubtractClash=airport.shifts[0].landingSubtractClash;
            var shift2LandingSubtractClash=airport.shifts[1].landingSubtractClash;
            var shift1Clash=airport.shifts[0].clash;
            var shift2clash=airport.shifts[1].clash;
            var shift1Grabbed=airport.shifts[0].grabbed;
            var shift2Grabbed=airport.shifts[1].grabbed;
            statistic.push({
                name: "1 vs 2",
                revenue: formatStatisticResult((shift2Revenue-shift1Revenue)/shift1Revenue* 100).toFixed(0),
                landingSubtractClash: formatStatisticResult((shift2LandingSubtractClash-shift1LandingSubtractClash)/ shift1LandingSubtractClash * 100).toFixed(0),
                clash: formatStatisticResult((shift2clash-shift1Clash)/ shift1Clash * 100).toFixed(0),
                grabbed: formatStatisticResult((shift2Grabbed-shift1Grabbed) / shift1Grabbed * 100).toFixed(0)
            });
        }
        if (airport.shifts.length > 2) {
            var shift2Revenue=airport.shifts[1].revenue;
            var shift3Revenue=airport.shifts[2].revenue;
            var shift2LandingSubtractClash=airport.shifts[1].landingSubtractClash;
            var shift3LandingSubtractClash=airport.shifts[2].landingSubtractClash;
            var shift2Clash=airport.shifts[1].clash;
            var shift3clash=airport.shifts[2].clash;
            var shift2Grabbed=airport.shifts[1].grabbed;
            var shift3Grabbed=airport.shifts[2].grabbed;
            statistic.push({
                name: "2 vs 3",
                revenue: formatStatisticResult((shift3Revenue-shift2Revenue)/shift2Revenue* 100).toFixed(0),
                landingSubtractClash: formatStatisticResult((shift3LandingSubtractClash-shift2LandingSubtractClash)/ shift2LandingSubtractClash * 100).toFixed(0),
                clash: formatStatisticResult((shift3clash-shift2Clash)/ shift2Clash * 100).toFixed(0),
                grabbed: formatStatisticResult((shift3Grabbed-shift2Grabbed) / shift2Grabbed * 100).toFixed(0)
            });
        }
        setAirportShiftStatistic(statistic);
    }
    useEffect(() => {
        request.get("api/game-result").then(resp => {
            console.log(resp.data);
            setAirportStatistic(resp.data)
            var categories = resp.data.map(a => a.airportName);
            var series = [];
            resp.data[0].shifts.forEach(a => {
                series.push({
                    name: "Shift " + a.index,
                    data: resp.data.map(b => b.shifts.find(s => s.index == a.index).revenue)
                })
            })
            var title = formatStatisticResult(sum(resp.data.map(a => a.revenue)) / sum(resp.data.map(a => a.target)) * 100).toFixed(1) + "% OF TARGET REACHED";
            renderChart("combinedContainer", title, categories, series)

            var statistic = [];
            if (resp.data[0].shifts.length > 1) {

                var shift1Revenue=sum(resp.data.map(a => a.shifts[0].revenue));
                var shift2Revenue=sum(resp.data.map(a => a.shifts[1].revenue));
                var shift1LandingSubtractClash=sum(resp.data.map(a => a.shifts[0].landingSubtractClash));
                var shift2LandingSubtractClash=sum(resp.data.map(a => a.shifts[1].landingSubtractClash));
                var shift1Clash=sum(resp.data.map(a => a.shifts[0].clash));
                var shift2clash=sum(resp.data.map(a => a.shifts[1].clash));
                var shift1Grabbed=sum(resp.data.map(a => a.shifts[0].grabbed));
                var shift2Grabbed=sum(resp.data.map(a => a.shifts[1].grabbed));
                statistic.push({
                    name: "1 vs 2",
                    revenue: formatStatisticResult((shift2Revenue-shift1Revenue)/shift1Revenue* 100).toFixed(0),
                    landingSubtractClash: formatStatisticResult((shift2LandingSubtractClash-shift1LandingSubtractClash)/ shift1LandingSubtractClash * 100).toFixed(0),
                    clash: formatStatisticResult((shift2clash-shift1Clash)/ shift1Clash * 100).toFixed(0),
                    grabbed: formatStatisticResult((shift2Grabbed-shift1Grabbed) / shift1Grabbed * 100).toFixed(0)
                });
            }
            if (resp.data[0].shifts.length > 2) {
                var shift2Revenue=sum(resp.data.map(a => a.shifts[1].revenue));
                var shift3Revenue=sum(resp.data.map(a => a.shifts[2].revenue));
                var shift2LandingSubtractClash=sum(resp.data.map(a => a.shifts[1].landingSubtractClash));
                var shift3LandingSubtractClash=sum(resp.data.map(a => a.shifts[2].landingSubtractClash));
                var shift2Clash=sum(resp.data.map(a => a.shifts[1].clash));
                var shift3clash=sum(resp.data.map(a => a.shifts[2].clash));
                var shift2Grabbed=sum(resp.data.map(a => a.shifts[1].grabbed));
                var shift3Grabbed=sum(resp.data.map(a => a.shifts[2].grabbed));
                statistic.push({
                    name: "2 vs 3",
                    revenue: formatStatisticResult((shift3Revenue-shift2Revenue)/shift2Revenue* 100).toFixed(0),
                    landingSubtractClash: formatStatisticResult((shift3LandingSubtractClash-shift2LandingSubtractClash)/ shift2LandingSubtractClash * 100).toFixed(0),
                    clash: formatStatisticResult((shift3clash-shift2Clash)/ shift2Clash * 100).toFixed(0),
                    grabbed: formatStatisticResult((shift3Grabbed-shift2Grabbed) / shift2Grabbed * 100).toFixed(0)
                });
            }
            setCombinedShiftStatistic(statistic);
            setAirport({ airportName: resp.data[0].airportName, shiftName: "Shift 1" });
        })

    }, [])
    useEffect(() => {
        if (!selectedAirport) return;
        bindAirport(selectedAirport);
    }, [selectedAirport?.airportName]);
    return (

        <div className="mx-5 my-2">
            <div className="container-fluid">
                <Row>
                    <Col ></Col>
                    <Col style={{ marginBottom: "-32px", zIndex: 1 }}>
                        <Button variant="primary" size="sm" onClick={() => history.push('/admin')} className="mx-2 float-end" >
                            <BsFillPersonLinesFill />
                            Back To Admin
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="patchResults">
                            <Tab eventKey="patchResults" title="Patch Results">
                                <Container fluid>
                                    <Row className='game-result'>
                                        {airportStatistic.map(statistic =>
                                            <Col md={4} key={statistic.airportId}>
                                                <Row>
                                                    <Col md={2}></Col>
                                                    <Col key={statistic.airportId} md={8} className="pt-5">
                                                        <Row className="text-center">
                                                            <Col md={12} className="h4 airport-name py-2"> {statistic.airportName}</Col>
                                                            <Col md={12} className="img-container">   <img src="./game-logo.png" className="game-result-img img-fluid" alt="logo" /></Col>
                                                            {
                                                                statistic.revenue > statistic.target ?

                                                                    <Col md={12} className="best-team py-2"> {statistic.teams[0].name}</Col>
                                                                    :
                                                                    <Col md={12} className="target-reached py-2">  {formatStatisticResult(statistic.revenue / statistic.target * 100).toFixed(1) + "% OF TARGET REACHED"}</Col>
                                                            }


                                                        </Row>
                                                    </Col>
                                                    <Col md={2}></Col>
                                                </Row>
                                            </Col>
                                        )
                                        }
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="airports" title="Game Result">
                                <Container fluid >
                                    <Row>
                                        <Col md={6}>
                                            <h4 className='py-4'> Combined Productiveness // Team revenue over shifts</h4>
                                            <div id="combinedContainer" style={{ minWidth: "400px", height: "400px" }}></div>
                                            <h4 className='py-4'> Combined Effectiveness // % difference from shift 1 vs shift 2 vs shift 3</h4>
                                            <Table striped bordered hover className='text-center'>
                                                <thead>
                                                    <tr >
                                                        <th width="80px"></th>
                                                        <th>Revenue</th>
                                                        <th>Landings</th>
                                                        <th>Clashes</th>
                                                        <th>Opportunities</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            Money from landing aircraft, less any penalities
                                                        </td>
                                                        <td>
                                                            Aircraft landed, less 'forced' aircraft
                                                        </td>
                                                        <td>
                                                            Aircraft needed to be rerouted due to insufficient time between landings
                                                        </td>
                                                        <td>
                                                            Aircraft grabbed from the Stack
                                                        </td>
                                                    </tr>
                                                    {combinedShiftStatistic.map(statistic =>
                                                        <tr key={statistic.name}>
                                                            <td>
                                                                {statistic.name}
                                                            </td>
                                                            <td>
                                                                {statistic.revenue + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.landingSubtractClash + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.clash + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.grabbed + "%"}
                                                            </td>
                                                        </tr>
                                                    )}


                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className='py-4'> Airport Productiveness // Team revenue over shifts</h4>
                                            <div id="airportContainer" style={{ minWidth: "400px", height: "400px" }}></div>
                                            <h4 className='py-4'> Airport Effectiveness // % difference from shift 1 vs shift 2 vs shift 3</h4>
                                            <Table striped bordered hover className='text-center'>
                                                <thead>
                                                    <tr >
                                                        <th width="80px"></th>
                                                        <th>Revenue</th>
                                                        <th>Landings</th>
                                                        <th>Clashes</th>
                                                        <th>Opportunities</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            Money from landing aircraft, less any penalities
                                                        </td>
                                                        <td>
                                                            Aircraft landed, less 'forced' aircraft
                                                        </td>
                                                        <td>
                                                            Aircraft needed to be rerouted due to insufficient time between landings
                                                        </td>
                                                        <td>
                                                            Aircraft grabbed from the Stack
                                                        </td>
                                                    </tr>
                                                    {airportShiftStatistic.map(statistic =>
                                                        <tr key={statistic.name}>
                                                            <td>
                                                                {statistic.name}
                                                            </td>
                                                            <td>
                                                                {statistic.revenue + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.landingSubtractClash + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.clash + "%"}
                                                            </td>
                                                            <td>
                                                                {statistic.grabbed + "%"}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {selectedAirport && <TeamScore airportName={selectedAirport.airportName} shiftName={selectedAirport.shiftName} />}

                                        </Col>
                                    </Row>
                                </Container>

                            </Tab>

                        </Tabs>
                    </Col>
                </Row>

            </div>


        </div>


    );

}

export default GameResult;
