import { Spinner, Row, Col, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import './Main.css'
import Admin from "./Admin"
import {
  HashRouter as Router,
  Redirect,
  NavLink,
  Route,
  Switch,
  useHistory
} from "react-router-dom";

import Player from "./Player";
import Dashboard from "./Dashboard";
import GameContext from "./GameContext";
import GameResult from "./GameResult";
import request from "./Api";
function App() {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setErrorShow] = useState(false);
  const [game, setGame] = useState(undefined);
  function loadGame() {
    request.get("api/game").then(response => {
      setGame(response.data);
    });
  }
  const updateGame = () => {
    loadGame();
  }
  useEffect(() => {
    console.log("App useEffect");
    request.interceptors.request.use(
      function (config) {
        setShow(true);
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    request.interceptors.response.use(
      function (response) {
        setShow(false);
        console.log(response.data);
        return response;
      },
      function (error) {
        setShow(false);
        console.log(error.response);
        if (error.response.status == "401") {

        }
        else if (error.response.status == "400" || error.response.status == "500") {
          setErrorShow(true);
          setErrorMessage(error.response.data);
        }
        else if (error.response.status == "403") {

        }
        return Promise.reject(error);
      }
    );
    loadGame();
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <GameContext.Provider value={game}>


        <Router>
          <Switch>GameResult
            <Route exact path="/result" component={GameResult} />
            <Route exact path="/" component={Player} />
            <Route exact path="/game" component={Dashboard} />
            <Route exact path="/admin" >
              <Admin updateGame={updateGame} />
            </Route>
          </Switch>
        </Router>

      </GameContext.Provider>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
      >

        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col md="1"> <Spinner animation="border" /></Col>
            <Col md="3"><h4>Loading...</h4></Col>
          </Row>


        </Modal.Body>

      </Modal>

      <Modal show={showError} onHide={() => setErrorShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setErrorShow(false)}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </DndProvider>
  );
}
export default App;
