import { Container, Row, Col, Table, ToastContainer, Toast, Button, Form } from "react-bootstrap";
import { useState, useEffect, useContext } from 'react'
import GameContext from "./GameContext";
import { io } from "socket.io-client";
import { useCookies } from 'react-cookie';
import { BsFillCircleFill } from "react-icons/bs";
import AircraftContainer from "./AircraftContainer";
import Aircraft from "./Aircraft";
import { createHashHistory } from 'history';
import { BsFillPersonLinesFill } from "react-icons/bs"
import request from "./Api";
require('dotenv').config();
var gameSocket;
function Dashboard({ location }) {
    const game = useContext(GameContext);
    const [toastInfo, setToastInfo] = useState({ isShow: false, message: "" });
    const [currentShift, setCurrentShift] = useState(undefined);
    const [teamComment, setTeamComment] = useState({ isChanged: false, comment: "" });
    const [cookies, setCookie] = useCookies(['state', 'isAdmin']);
    const [gameData, setData] = useState({ aircrafts: [] });
    const history = createHashHistory();
    function Edit(id, runway) {
        gameSocket.emit('move', { aircraftId: id, teamId: cookies.state.teamId, teamName: cookies.state.teamName, runway: runway });
    }
    function Push(id) {
        gameSocket.emit('push', { aircraftId: id, teamId: cookies.state.teamId, });
    }
    const IfNullReturn0 = function (value) {
        if (value) return value;
        return 0;
    }
    const commentChanged = function (event) {
        if (teamComment.isChanged) {
            var command = {
                airportId: cookies.state.airportId,
                teamId: cookies.state.teamId,
                comment: event.target.value
            }
            request.put("api/team/edit-comment", command).then(response => {
                setToastInfo({ isShow: true, message: "Team strategy update success!" });
            })
        }
    }
    useEffect(() => {
        gameSocket = io(process.env.REACT_APP_BASEURl + cookies.state.airportId);
        gameSocket.on('connect', () => {
            console.log(gameSocket.id);
            var data = {
                airportId: cookies.state.airportId,
                teamId: cookies.state.teamId,
                memberId: "c77ee8d8-5e1e-41c7-8364-2bceae921dab",
                clientId: "Test"
            };
            console.log(data);
            gameSocket.emit("registerClientInfo", data)

        });
        gameSocket.on("message", (data) => {
            console.log("message", data);
            if (data.code == "CanNotMove") {
                setToastInfo({ isShow: true, message: data.message });
            }
            else if (data.code == "GameBeenClean") {
                setData({ aircrafts: [] });
                setToastInfo({ isShow: true, message: "Game been clean!" });
            }
            else if (data.code == "GameStopped") {
                setData({ aircrafts: [] });
                setToastInfo({ isShow: true, message: "Game been stopped!" });
            }
            else if (data.code == "NewShift") {
                setToastInfo({ isShow: true, message: "New shift start!" });
                setCurrentShift(data.shiftData);
            }
            else if (data.code == "FirstShift") {
                setCurrentShift(data.shiftData);
            } else if (data.code == "TeamCommentChanged") {

                if (data.team.teamId == cookies.state.teamId) {
                    setTeamComment({ isChanged: false, comment: data.team.comment });
                }

            }
        });
        gameSocket.on('gameStarted', (data) => {
            console.log(data);
            setData(data);
        });
        return () => {
            gameSocket.disconnect();
          };
    }, [])
    return (
        <Container fluid>
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast onClose={() => setToastInfo({ isShow: false })} show={toastInfo.isShow} delay={10000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Controller Chaos</strong>
                    </Toast.Header>
                    <Toast.Body>{toastInfo.message}</Toast.Body>
                </Toast>
            </ToastContainer>
            <Row>

                <Col>
                    <br />
                </Col>

            </Row>
            <Table bordered>
                <tbody>

                    <tr>
                        <td colSpan={2}>
                            <img src="./game-logo.png" className="img-fluid" alt="logo" />
                        </td>

                        <td>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12} className="pt-2">
                                            {game && <h4 className="d-inline">
                                                <strong className="me-2">Game Status:</strong>
                                                <small className={
                                                    game.status == "Active" ? "text-success" :
                                                        game.status == "Start" ? "text-primary" :
                                                            game.status == "Deactive" ? "text-muted  text-opacity-25" : ""}>
                                                    <BsFillCircleFill /> {game.status}</small></h4>}
                                            <h4 className="ms-5 d-inline"> <strong> Airport:</strong> <small>{cookies.state.airportName}</small></h4>
                                            <h4 className="ms-5 d-inline"> <strong> Team:</strong> <small>{cookies.state.teamName}</small></h4>

                                        </Col>
                                        <Col md={12} className="pt-3">
                                            <strong> Shift:</strong> <small>{currentShift ? currentShift.number : 0}</small>
                                            <strong className="ms-3"> Shift Start:</strong> <small>{currentShift ? new Date(currentShift.startTime).toTimeString().substring(0, 5) : ""}</small>
                                            <strong className="ms-3"> Shift End:</strong> <small>{currentShift ? new Date(currentShift.endTime).toTimeString().substring(0, 5) : ""}</small>

                                            <strong className="ms-3">Ranking:</strong> <small>{game&&currentShift&&currentShift.number==game.settings.shiftCount?'--':gameData.rank}</small>
                                            <strong className="ms-3"> A/Port REV:</strong> <small>${gameData.revenue}</small>

                                            {cookies.isAdmin &&
                                                <Button variant="primary" size="sm" onClick={() => history.push('/admin')} className="mx-2" >
                                                    <BsFillPersonLinesFill />
                                                    Back To Admin
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form>
                                        <Form.Group controlId="teamComment">
                                            <Form.Control as="textarea" placeholder="Team Strategy..." value={teamComment.comment} onChange={(event) => setTeamComment({ isChanged: true, comment: event.target.value })} rows={3} onBlur={commentChanged} />
                                        </Form.Group>
                                    </Form>
                                </Col>

                            </Row>
                        </td>
                    </tr>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#87ceeb" }}>
                            <div className="vertical-text">STACK</div>
                        </td>
                        <td style={{ width: "150px", verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.airLanded)}</small></span>

                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.airReroute)}</small></span>
                        </td>
                        <td>
                            {gameData.aircrafts?.filter(a => a.runway === "").map(a => <Aircraft runway={""} key={a.id} aircraft={a} />)}
                        </td>
                    </tr>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#ff743d" }}>
                            <div className="vertical-text">TEAM</div>
                        </td>
                        <td style={{ width: "100px", verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.teamLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.teamReroute)}</small></span>
                            <br />
                            {gameData.teamLast &&
                                <span><strong> Last:</strong> <small>{new Date(gameData.teamLast).toTimeString().substring(0, 8)}</small></span>}
                        </td>
                        <td>
                            <AircraftContainer runway="team" aircrafts={gameData.aircrafts?.filter(a => a.runway === "team")} updateBlocks={Edit} push={Push} />
                        </td>
                    </tr>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#999999" }}>
                            <div className="vertical-text">AUXILIARY</div>
                        </td>
                        <td style={{ width: "100px", verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.auxLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.auxReroute)}</small></span>
                            <br />
                            {gameData.auxLast &&
                                <span><strong> Last:</strong> <small>{new Date(gameData.auxLast).toTimeString().substring(0, 8)}</small></span>}
                        </td>

                        <td>
                            <AircraftContainer runway="aux" aircrafts={gameData.aircrafts?.filter(a => a.runway === "aux")} updateBlocks={Edit} push={Push} />
                        </td>
                    </tr>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#d9d9d9" }}>
                            <div className="vertical-text">HELIPAD</div>
                        </td>
                        <td style={{ width: "100px", verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.helipadLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.helipadReroute)}</small></span>
                            <br />
                            {gameData.helipadLast &&
                                <span><strong> Last:</strong> <small>{new Date(gameData.helipadLast).toTimeString().substring(0, 8)}</small></span>}
                        </td>

                        <td>
                            <AircraftContainer runway="helipad" aircrafts={gameData.aircrafts?.filter(a => a.runway === "helipad")} updateBlocks={Edit} push={Push} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    )
}


export default Dashboard;